<template>
  <div id="app" class="artTrain">
    <Nav />
    <div class="mt-50">
      <img style="width: 100%" src="@/assets/home/banner.png" />
      <!-- 中心简介 -->
      <div class="artTraining-title flex center mt-25">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">中心简介</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-1.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-hz1">
        <div class="color1 f-12">
          苏州民族管弦乐团社会培训部(苏州新乐府艺术培训中心有限公司),为苏州民 族管弦乐团全资子公司，致力于青少年民族音乐教育，通过传播中华优秀文化， 繁荣江南丝竹艺术，从而引导青少年用传统民族艺术语言讲好华夏故事、弘扬民 族精神。依托苏州民族管弦乐团专家指导以及优秀青年演奏家的人才优势，着力 打造集“权威师资、标准课程、多元实践、惠民价格”为一体的艺术教育优质品牌。
        </div>
      </div>
      <!-- 教师风采 -->
      <div class="artTraining-title flex center mt-35">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">教师风采</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-7.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-teacher-top">
        <img class="teacher-img" src="@/assets/art/art-8.png" alt="" />
      </div>
      <div class="teacher-ul flex between wrap">
        <div class="teacher-li" v-for="tea in 19" :key="tea">
          <img :src="require(`@/assets/art/art-${33 + tea}.png`)" alt="" />
        </div>
      </div>
      <!-- 课程设置 -->
      <div class="artTraining-title flex center mt-35">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">课程设置</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-12.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <!-- 名族器乐 -->
      <div class="artTraining-hz2 mt-25">
        <!-- <div class="artTraining-nation-top">民族器乐</div> -->
        <div class="artTraining-nation-ul flex between wrap">
          <div
            class="artTraining-nation-li"
            v-for="(item, index) in nationList"
            :key="index"
          >
            <img class="nation-li-img" :src="item.url" alt="" />
            <div class="nation-li-title">{{ item.title }}</div>
          </div>
          <img class="nation-img" src="@/assets/art/art-21.png" alt="" />
        </div>
      </div>
      <!-- 收费标准 -->
      <div class="artTraining-title flex center mt-35">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">收费标准</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-56.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-hz2">
        <img class="mt-15" src="@/assets/art/art-53.png" alt="" />
      </div>
      <!-- 扫码约课 -->
      <div class="artTraining-title flex center mt-35">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">扫码约课</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-57.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-hz2">
        <div class="color1 f-12 mt-10">咨询 答疑 约课</div>
        <div class="color1 f-12">
          请用微信扫二维码添加苏州民族管弦乐团培训中心
        </div>
        <div class="qrcode mt-10">
          <img src="@/assets/art/art-qr.png" alt="" id="test" />
        </div>
      </div>
      <!-- 社会艺术水平考级 -->
      <div class="artTraining-title flex center mt-20">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">社会艺术水平考级(2025)</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-22.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-hz2 flex">
        <div class="artTraining-apply" @click="examination">
          <img src="@/assets/art/art-17.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img1" src="@/assets/art/art-28.png" alt="" />
            <p class="apply-hz-p">考级报名</p>
          </div>
        </div>
        <div
          class="artTraining-apply"
          @click="testRegistration('2', 'TestSearch', 'test')"
        >
          <img src="@/assets/art/art-18.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img2" src="@/assets/art/art-60.png" alt="" />
            <p class="apply-hz-p">参赛证下载</p>
          </div>
        </div>
        <div
          class="artTraining-apply"
          @click="testRegistration('2', 'TestSearch', 'testScoreInquiry')"
        >
          <img src="@/assets/art/art-19.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img2" src="@/assets/art/art-61.png" alt="" />
            <p class="apply-hz-p" id="teenager">成绩查询</p>
          </div>
        </div>
      </div>
      <!-- 第二届苏州市青少年民族器乐展演 -->
      <div class="artTraining-title flex center mt-35" id="teenager">
        <div class="artTraining-beam1"></div>
        <div class="artTraining-beam2"></div>
        <div class="artTraining-xhz">
          <p class="artTraining-xhz-p">第四届苏州市青少年民族器乐展演</p>
          <img
            class="artTraining-xhz-img"
            src="@/assets/art/art-23.png"
            alt=""
          />
        </div>
        <div class="artTraining-beam3"></div>
      </div>
      <div class="artTraining-hz2 flex mb-35">
        <div class="artTraining-apply" @click="examination">
          <img src="@/assets/art/art-20.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img1" src="@/assets/art/art-28.png" alt="" />
            <p class="apply-hz-p">展演报名</p>
          </div>
        </div>
        <div
          class="artTraining-apply"
          @click="testRegistration('2', 'TestSearch', 'teenager')"
        >
          <img src="@/assets/art/art-58.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img2" src="@/assets/art/art-60.png" alt="" />
            <p class="apply-hz-p">参赛证下载</p>
          </div>
        </div>
        <!-- 成绩查询 -->
        <div
          class="artTraining-apply"
          @click="testRegistration('2', 'TestSearch', 'teenagerScoreInquiry')"
        >
          <img src="@/assets/art/art-59.png" alt="" />
          <div class="artTraining-apply-hz flex column center">
            <img class="apply-hz-img2" src="@/assets/art/art-61.png" alt="" />
            <p class="apply-hz-p">成绩查询</p>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <Footer />
    <el-dialog :visible.sync="signUpDialogVisible" width="80%" align="center">
      <div align="center" class="mb-20 f-18">长按识别跳转小程序报名</div>
      <img
        src="@/assets/qrcode.png"
        alt=""
        srcset=""
        style="width: 150px; height: 150px"
        class="mb-30"
      />
    </el-dialog>
  </div>
</template>

<style scoped>
</style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      nationList: [
        {
          url: require("../../../assets/art/art-13.png"),
          title:'音乐启蒙(4-6岁)'
        },
        {
          url: require("../../../assets/art/art-14.png"),
          title:'器乐培训(1-10 级/6-12 岁)'
        },
        {
          url: require("../../../assets/art/art-15.png"),
          title:'艺术考学(初中学段/高中学段)'
        },
        {
          url: require("../../../assets/art/art-16.png"),
          title:'成人器乐(18 周岁以上)'
        }
      ],
      signUpDialogVisible: false,
    };
  },
  watch: {
    $route(newValue) {
      this.$nextTick(() => {
        if (newValue.query.type == 1) {
          document.body.scrollTop = 0;
        } else if (newValue.query.type == 2) {
          document.getElementById("test").scrollIntoView();
        } else if (newValue.query.type == 3) {
          document.getElementById("teenager").scrollIntoView();
        }
      });
    },
  },
  mounted() {
    if (this.$i18n.locale == "en") {
      return this.$router.push({ name: "Home" });
    }
    this.$nextTick(() => {
      if (this.$route.query.type == 1) {
        document.body.scrollTop = 0;
      } else if (this.$route.query.type == 2) {
        document.getElementById("test").scrollIntoView();
      } else if (this.$route.query.type == 3) {
        document.getElementById("teenager").scrollIntoView();
      }
    });
  },
  methods: {
    joinView(index) {
      this.joinList[index].show = !this.joinList[index].show;
    },
    // 查询下载
    testRegistration(val, name, type) {
      if (val == "1") {
        return this.$notify.info({
          title: "消息",
          message: "已结束或尚未开始",
        });
      }
      this.$router.push({ name: name, query: { type: type } });
    },
    // 社会水平考级报名
    examination() {
      this.signUpDialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.artTrain {
  img {
    max-width: 100%;
  }
}
.artTraining-title {
  height: 25px;
  overflow: hidden;
  .artTraining-beam1 {
    width: 20px;
    height: 1px;
    background-color: #b3b3b3;
  }
  .artTraining-beam2 {
    width: 4px;
    height: 25px;
    background-color: #b49a71;
  }
  .artTraining-xhz {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    position: relative;
    height: 25px;
    .artTraining-xhz-p {
      line-height: 18px;
    }
    .artTraining-xhz-img {
      height: 10px;
      position: relative;
      top: -9px;
    }
  }
  .artTraining-beam3 {
    flex: 1;
    height: 1px;
    background-color: #b3b3b3;
    margin-left: 14px;
  }
}
.artTraining-hz1 {
  padding: 13px 11px 0 29px;
  width: 100%;
  box-sizing: border-box;
}
.artTraining-hz2 {
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .artTraining-student {
    width: 49%;
    .artTraining-student-img1 {
      width: 100%;
    }
    .artTraining-student-img2 {
      width: 60%;
      margin-left: 8px;
    }
    .artTraining-student-img3 {
      width: 90%;
    }
    .artTraining-student-img4 {
      position: absolute;
      width: 55%;
      top: -119px;
    }
  }
}
.artTraining-nation-top {
  display: inline-block;
  color: #4d4d4d;
  font-size: 14px;
  line-height: 28px;
  border-bottom: 2px solid #b49a71;
}
.artTraining-nation-ul {
  width: 100%;
  position: relative;
  .artTraining-nation-li {
    width: 160px;
    // margin-right: 2%;
    position: relative;
    z-index: 22;
    .nation-li-img {
      width: 160px;
      height: 105px;
    }
    .nation-li-title{
      font-weight: 500;
     font-size: 13px;
      color: #242424;
      margin: 8px 0 12px;
    }
  }
  // .artTraining-nation-li:nth-child(2n) {
  //   margin-right: 0;
  // }
  .nation-img {
    position: absolute;
    right: -16px;
    top: -120px;
    width: 125px;
  }
}
.artTraining-strike {
  width: 32%;
  height: 112px;
  border-radius: 20px 0 20px 0;
  padding: 17px 8px;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  .artTraining-strike-p {
    line-height: 18px;
  }
  .artTraining-strike-but {
    width: 78px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 4px;
  }
}
.artTraining-apply {
  width: 32%;
  position: relative;
  margin-right: 2%;
  margin-top: 20px;
}
.artTraining-apply:nth-child(3n) {
  margin-right: 0;
}
.artTraining-apply-hz {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.apply-hz-img1 {
  width: 20px;
  height: 22px;
}
.apply-hz-img2 {
  width: 18px;
  height: 22px;
}
.apply-hz-p {
  margin-top: 15px;
  color: #fff;
  font-size: 14px;
}
.back1 {
  background-color: #634636;
}
.back2 {
  background-color: #b49a71;
}
.back3 {
  background-color: #292929;
}
.artTraining-teacher-top {
  width: 100%;
  height: 34px;
  text-align: right;
  margin-top: -10px;
  .teacher-img {
    width: 156px;
    height: 34px;
    margin-right: 22px;
  }
}
.teacher-ul {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  .teacher-li {
    width: 30%;
    margin-top: 12px;
  }
}
.qrcode {
  width: 40%;
  border: 1px dashed #000;
  display: flex;
}
</style>
